import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./piracy.css";

const PiracyCheck = ({ location = {} }) => {
  const [data, setData] = useState([]);
  const [dataa, setDataa] = useState([]);
  const [searchBarcode, setSearchBarcode] = useState("");
  const [foundBarcode, setFoundBarcode] = useState(null);
  const [barcodeCount, setBarcodeCount] = useState(null);
  const [submittedBarcode, setSubmittedBarcode] = useState("");
  const [displayedDorderid, setDisplayedDorderid] = useState("");
  const [SearchBarcode1, setSearchBarcode1] = useState("");
  const { state } = location;

  const lastScannedBarcode = state && state.lastScannedBarcode;

  useEffect(() => {
    Axios.get("https://nodei.ssccglpinnacle.com/getship").then((response) => {
      const reversedData = response.data.reverse();
      setData(reversedData);
    });
    Axios.get("https://nodei.ssccglpinnacle.com/getApproveDPO").then((response) => {
      const reversedData = response.data.reverse();
      setDataa(reversedData);
    });
  }, []);

  const postBarcodeToMongoDB = async () => {
    try {
      await Axios.post("https://nodei.ssccglpinnacle.com/barcodeadd", {
        barcode: lastScannedBarcode,
      });
      console.log("Barcode posted to MongoDB successfully.");
    } catch (error) {
      console.error("Error posting barcode to MongoDB:", error);
    }
  };

  const getCountFromAPI = async (barcode) => {
    try {
      const response = await Axios.post("https://nodei.ssccglpinnacle.com/count", {
        barcode,
      });
      setBarcodeCount(response.data.count);
    } catch (error) {
      console.error("Error getting barcode count:", error);
    }
  };

  const handleSearch = async () => {
    if (lastScannedBarcode.trim() === "") {
      alert("Please enter a barcode before submitting.");
    }
    let found = false;
    let orderNum = null;

    if (!lastScannedBarcode.includes("-")) {
      const response1 = await Axios.get(
        `https://nodei.ssccglpinnacle.com/getKey/${lastScannedBarcode}`
      );
      const currentSearchBarcode1 = response1.data.key;
      setSearchBarcode1(currentSearchBarcode1);

      data.forEach((item) => {
        if (item.barcodeData) {
          item.barcodeData.forEach((barcode) => {
            if (
              barcode.scannedData &&
              barcode.scannedData.includes(currentSearchBarcode1)
            ) {
              found = true;
              orderNum = barcode.OrderNum;
              return;
            }
          });
        }
      });
      setFoundBarcode(
        found
          ? "verified"
          : "This book does not belong to Pinnacle so this is a duplicate book."
      );
      setSubmittedBarcode(lastScannedBarcode);
      setSearchBarcode("");
      console.log(currentSearchBarcode1);
      if (found) {
        postBarcodeToMongoDB();
        // Check in dataa array for matching shipmentid
        const matchingOrder = dataa.find(
          (order) => order.shipmentid === orderNum
        );

        if (matchingOrder) {
          setDisplayedDorderid(matchingOrder.Dorderid);
          console.log(`Dorderid: ${matchingOrder.Dorderid}`);
        } else {
          setDisplayedDorderid(""); // Set to empty string if no matching order is found
        }
      } else {
        setDisplayedDorderid(""); // Set to empty string if found is false
      }

      getCountFromAPI(lastScannedBarcode);
      setBarcodeCount("");
    } else {
      setSearchBarcode1("");
      data.forEach((item) => {
        if (item.barcodeData) {
          item.barcodeData.forEach((barcode) => {
            if (
              barcode.scannedData &&
              barcode.scannedData.includes(lastScannedBarcode)
            ) {
              found = true;
              orderNum = barcode.OrderNum;
              return;
            }
          });
        }
      });
      setFoundBarcode(
        found
          ? "verified"
          : "This book does not belong to Pinnacle so this is a duplicate book."
      );
      setSubmittedBarcode(lastScannedBarcode);
      setSearchBarcode("");

      if (found) {
        postBarcodeToMongoDB();
        // Check in dataa array for matching shipmentid
        const matchingOrder = dataa.find(
          (order) => order.shipmentid === orderNum
        );

        if (matchingOrder) {
          setDisplayedDorderid(matchingOrder.Dorderid);
          console.log(`Dorderid: ${matchingOrder.Dorderid}`);
        } else {
          setDisplayedDorderid(""); // Set to empty string if no matching order is found
        }
      } else {
        setDisplayedDorderid(""); // Set to empty string if found is false
      }

      getCountFromAPI(lastScannedBarcode);
      setBarcodeCount("");
    }
  };

  return (
    <div>
      <label className="findbook">
        <h2>For Distributors</h2>
        <h5>Enter Multipurpose Unique Code Mentioned on the Book</h5>
      </label>
      <br />

      <label className="findbooksearch">
        Search :-{" "}
        <input
          type="text"
          placeholder="Search Barcode"
          value={lastScannedBarcode}
          onChange={(e) => setSearchBarcode(e.target.value.trim())}
        />
        <button className="search-button" onClick={handleSearch}>
          Submit
        </button>
        {submittedBarcode && <p>Submitted Barcode: {submittedBarcode}</p>}
        {SearchBarcode1 && <p>Serial Number: {SearchBarcode1}</p>}
      </label>
      <label
        className={`verified ${foundBarcode === "verified" ? "green" : "red"}`}
      >
        {foundBarcode && (
          <p
            style={{
              color: foundBarcode.includes(
                "This book does not belong to Pinnacle so this is a duplicate book."
              )
                ? "red"
                : "inherit",
            }}
          >
            {foundBarcode}
          </p>
        )}
      </label>

      {barcodeCount !== null && (
        <p id="pira">{`Barcode verified ${barcodeCount} time(s).`}</p>
      )}
      {barcodeCount > 2 && (
        <h4 style={{ color: "red", marginLeft: "40rem" }}>
          {" "}
          so it is Doubtful
        </h4>
      )}
      {displayedDorderid && (
        <p id="distri">Distributor : {displayedDorderid}</p>
      )}
    </div>
  );
};

export default PiracyCheck;
